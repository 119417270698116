import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Tooltip, Typography, Avatar, Link as MuiLink } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getSerializedSearch } from '../../helpers';
import RelatedDocumentsMenu from '../menus/RelatedDocumentsMenu';
import CompanyBlacklistComponent from './CompanyBlacklistComponent';
import { requestingPlatformMapperImage } from '../../assets';
import CompanyDebtsSummary from './CompanyDebtsSummary';
import selectedCompanyFilter from '../filters/selectedCompanyFilter';
import CompanyProfileDialog from '../dialogs/CompanyProfileDialog';
import DebtorProfileDialog from '../dialogs/DebtorProfileDialog';
import { useBooleanState } from '../../hooks';

const CompanyProfileCell = ({ masterEntity, showRelatedDocuments, showDicom, type }) => {
  const [open, setOpen] = useBooleanState(false);
  const history = useHistory();

  const handleClick = () => {
    if (showRelatedDocuments) {
      selectedCompanyFilter(masterEntity);
      history.push(`/app/comercial/invoices-management/preoffers-manager?${getSerializedSearch({ companyId: masterEntity.id })}`);
    } else {
      setOpen(true);
    }
  };

  if (!masterEntity) return <></>;

  return (
    <>
      <Stack spacing={0} direction="column">
        <Stack spacing={0.5} direction="row">
          <MuiLink
            textAlign="initial"
            variant="body2"
            rel="noopener noreferrer"
            underline="hover"
            onClick={handleClick}
          >
            <Typography style={{ wordWrap: 'break-word' }}>
              {masterEntity.name || masterEntity.displayNationalIdentifier}
            </Typography>
          </MuiLink>
          {showRelatedDocuments && <RelatedDocumentsMenu masterEntity={masterEntity} />}
        </Stack>
        <Stack direction="row">
          <Typography>{masterEntity.displayNationalIdentifier}</Typography>
          <CompanyBlacklistComponent masterEntity={masterEntity} disableDisplay={!showDicom} />
          <CompanyDebtsSummary masterEntity={masterEntity} type={type} />
          {'source' in masterEntity && masterEntity.source.name === 'Santander' && (
            <Tooltip title={`Empresa proveniente de ${masterEntity.source.name}`}>
              <Avatar
                src={requestingPlatformMapperImage[masterEntity.source.name.toUpperCase()]}
                sx={{ width: 15, height: 15 }}
              />
            </Tooltip>
          )}
        </Stack>
      </Stack>
      {open && !showRelatedDocuments && (
        type === 'receiver' ? (
          <DebtorProfileDialog
            masterEntityId={masterEntity.id}
            open={open}
            setOpen={setOpen}
            showDicom={showDicom}
          />
        ) : (
          <CompanyProfileDialog
            masterEntityId={masterEntity.id}
            open={open}
            setOpen={setOpen}
            showDicom={showDicom}
          />
        )
      )}
    </>
  );
};

CompanyProfileCell.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    source: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    displayNationalIdentifier: PropTypes.string.isRequired,
    company: PropTypes.shape({
      pendingReintegrations: PropTypes.shape({
        count: PropTypes.number,
      }),
      debtInvoices: PropTypes.shape({
        count: PropTypes.number,
        amountWithIva: PropTypes.shape({
          chileFormat: PropTypes.string,
        }),
      }),
    }),
    riskBlacklist: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  showRelatedDocuments: PropTypes.bool,
  showDicom: PropTypes.bool,
  type: PropTypes.oneOf(['company', 'receiver']),
};

CompanyProfileCell.defaultProps = {
  showRelatedDocuments: false,
  showDicom: true,
  type: 'company',
};

export default CompanyProfileCell;
