import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { green } from '@mui/material/colors';
import AlertsGrid from '../../grids/AlertsGrid';

const MexicoOperationalAlertsArray = ({ invoice }) => {
  const alertsArray = [];
  if (invoice.hasPaymentsEmitted) {
    alertsArray.push(
      {
        value: true,
        label: 'payment',
        tooltip: 'Con pagos asociados',
        color: green[500],
        icon: <Typography sx={{ fontSize: 10 }}>P</Typography>,
      },
    );
  }
  return (
    <AlertsGrid invoice={invoice} alerts={alertsArray} />
  );
};

MexicoOperationalAlertsArray.propTypes = {
  invoice: PropTypes.shape({
    hasPaymentsEmitted: PropTypes.bool,
  }).isRequired,
};

export default MexicoOperationalAlertsArray;
