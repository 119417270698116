import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
import { useBooleanState } from '../../hooks';
import { FingoDialog } from '../dialogs';
import { MasterEntityType, ArrayOfId } from '../../propTypes';
import { CONTACTS } from '../../graphql';
import { ContactList } from '../lists';
import { CONTACT_COLUMNS } from '../../constants/contact-columns';

const SummaryContactCard = ({
  masterEntity,
  contactType,
  showAllContactTypes,
  setSelectedContact,
  selectedContacts,
  columns,
}) => {
  const [open, toggleDialog, setTrue] = useBooleanState(false);

  const { data, loading } = useQuery(CONTACTS, {
    variables: {
      masterEntity_In: [masterEntity?.id],
      showHidden: false,
      ...(showAllContactTypes ? {} : { contactType }),
    },
    skip: !masterEntity?.id,
    fetchPolicy: 'network-only',
  });

  const contacts = data?.getContacts?.edges?.map((edge) => edge?.node) || [];
  const contact = contacts[0];

  if (loading) {
    return (
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardContent>
          <CircularProgress color="inherit" size="30px" />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          '&:hover': { boxShadow: 1, borderRadius: 2 },
        }}
        onClick={setTrue}
      >
        <CardContent>
          {contact ? (
            <Stack>
              <Typography variant="h5">Información de contacto</Typography>
              <Typography variant="body2" noWrap>
                <strong>Nombre:</strong> {contact.name}
              </Typography>
              <Typography variant="body2" noWrap>
                <strong>Cargo:</strong> {contact.position}
              </Typography>
              <Typography variant="body2" noWrap>
                <strong>Email:</strong> {contact.email}
              </Typography>
              <Typography variant="body2" noWrap>
                <strong>Telefono:</strong> {contact.phoneNumber}
              </Typography>
            </Stack>
          ) : (
            <Stack justifyContent="center">
              <Typography>Sin contactos</Typography>
              <Typography variant="h5">+ Nuevo contacto</Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
      <FingoDialog
        open={open}
        handleClose={toggleDialog}
        title="Lista de contactos"
        PaperProps={{ sx: { borderRadius: 4, maxWidth: 900 } }}
        fullWidth
        dialogActionButton={(
          <Button
            id="close-edit-contacts-dialog"
            color="primary"
            variant="contained"
            onClick={toggleDialog}
          >
            Cerrar
          </Button>
        )}
      >
        <ContactList
          showAllContactTypes={showAllContactTypes}
          masterEntity={masterEntity}
          contactType={contactType}
          setSelectedContactIds={setSelectedContact}
          selectedContactIds={selectedContacts}
          columns={columns}
          checkbox={false}
        />
      </FingoDialog>
    </>
  );
};

SummaryContactCard.propTypes = {
  masterEntity: MasterEntityType.isRequired,
  contactType: PropTypes.string,
  setSelectedContact: PropTypes.func,
  selectedContacts: ArrayOfId,
  showAllContactTypes: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array,
};

SummaryContactCard.defaultProps = {
  setSelectedContact: () => {},
  selectedContacts: [],
  columns: CONTACT_COLUMNS,
  contactType: 'COMMERCIAL',
  showAllContactTypes: false,
};

export default SummaryContactCard;
