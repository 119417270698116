import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import moment from 'moment';
import { formatDayMonthYear, formatTime } from '../../helpers';

const DateTimeCell = ({ fullDate }) => {
  const date = formatDayMonthYear(fullDate);
  const time = formatTime(fullDate);
  return (
    <Stack width="100%" alignItems="flex-start">
      <Typography variant="body2" width="inherit" noWrap>{date}</Typography>
      <Typography variant="caption" width="inherit" noWrap>{time}</Typography>
    </Stack>
  );
};

DateTimeCell.propTypes = {
  fullDate: PropTypes.instanceOf(moment).isRequired,
};

export default DateTimeCell;
