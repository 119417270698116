import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { AmountWithIvaMessage } from '../tooltips';
import MoneyCell from './MoneyCell';
import { MoneyType } from '../../propTypes';

const AmountWithIvaCell = ({ row }) => (
  <Stack alignItems="flex-start" justifyContent="center" width="100%">
    <Stack spacing={0.5} direction="row">
      <MoneyCell amount={row.amountWithIva} />
    </Stack>
    <AmountWithIvaMessage
      creditNotes={row.creditNotes}
      debitNotes={row.debitNotes}
    />
  </Stack>
);

AmountWithIvaCell.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amountWithIva: MoneyType.isRequired,
    creditNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
    debitNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default AmountWithIvaCell;
