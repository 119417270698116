import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import FingoLink from '../links/FingoLink';
import LightningFastPayment from '../tooltips/LightningFastPayment';
import { InvoiceType } from '../../propTypes';

const FolioCell = ({ invoice, showLightningFastPayment }) => {
  const { id, folio } = invoice;
  return (
    <>
      <Tooltip title={folio.length > 10 ? folio : ''} placement="top">
        <FingoLink
          id="invoice-profile"
          to={{
            pathname: '/app/invoice',
            search: `?invoiceId=${id}`,
          }}
          size="small"
          color="primary"
          variant="text"
          sx={{
            height: '30px',
            px: { xs: 0, md: 1 },
            py: 0,
            minWidth: { xs: 0, md: 64 },
          }}
        >
          <Typography
            sx={{
              maxHeight: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant="body2"
            align="left"
          >
            {folio}
          </Typography>
        </FingoLink>
      </Tooltip>
      {showLightningFastPayment && <LightningFastPayment invoice={invoice} />}
    </>
  );
};

FolioCell.propTypes = {
  invoice: InvoiceType.isRequired,
  showLightningFastPayment: PropTypes.bool,
};

FolioCell.defaultProps = {
  showLightningFastPayment: false,
};

export default FolioCell;
