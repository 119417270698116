import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import { companyCessionsTooltipHandler } from '../../constants/CessionsTooltip';

const CompanyCessionsTooltip = ({ company, children }) => {
  const companyCessionHistory = company?.cessionsHistory ?? null;
  const text = companyCessionsTooltipHandler(companyCessionHistory);
  return (
    <Tooltip
      title={(
        <Typography sx={{ whiteSpace: 'pre-wrap', fontSize: 11, margin: 2 }} variant="body2">
          {text || 'Empresa sin Historial de Cesiones'}
        </Typography>
      )}
      placement="right"
    >
      {children}
    </Tooltip>
  );
};

CompanyCessionsTooltip.propTypes = {
  company: PropTypes.shape({
    cessionsHistory: PropTypes.shape({
      cessionsNumber: PropTypes.number,
      cessionsMoneyAmount: PropTypes.number,
      historicCessionsNumber: PropTypes.number,
      historicCessionsMoneyAmount: PropTypes.number,
    }),
  }),
  children: PropTypes.node.isRequired,
};

CompanyCessionsTooltip.defaultProps = {
  company: {},
};

export default CompanyCessionsTooltip;
