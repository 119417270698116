import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Stack, InputAdornment } from '@mui/material';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useBooleanState, useSnackBars } from '../../hooks';
import { FingoDialog } from '../dialogs';
import { ADD_OR_EDIT_WHITELIST, MASTER_ENTITY_WHITELISTS, MASTER_ENTITIES_WHITELIST } from '../../graphql';

const AddOrEditWhitelistCell = ({ whitelist }) => {
  const isEditCell = whitelist !== null;
  const { addAlert } = useSnackBars();
  const [emitterRut, setEmitterRut] = useState(whitelist?.emitter?.displayNationalIdentifier ?? '');
  const [receiverRut, setReceiverRut] = useState(whitelist?.receiver?.displayNationalIdentifier ?? '');
  const [defaultRate, setDefaultRate] = useState(whitelist?.defaultRate ?? 0);
  const [monthlyRate, setMonthlyRate] = useState(whitelist?.monthlyRate ?? 0);
  const [retentionRate, setRetentionRate] = useState(whitelist?.retentionRate ?? 0);
  const [open, toggleDialog] = useBooleanState(false);
  const toggleOnClose = () => {
    if (!isEditCell) {
      setEmitterRut('');
      setReceiverRut('');
      setDefaultRate(0);
      setMonthlyRate(0);
      setRetentionRate(0);
    }
    toggleDialog();
  };
  const [editRateWhitelist, { loading }] = useMutation(
    ADD_OR_EDIT_WHITELIST,
    {
      variables: {
        emitterRut: !whitelist ? emitterRut : null,
        receiverRut: !whitelist ? receiverRut : null,
        whitelistId: whitelist?.id ?? null,
        defaultRate,
        monthlyRate,
        retentionRate,
      },
      refetchQueries: [MASTER_ENTITY_WHITELISTS, MASTER_ENTITIES_WHITELIST],
      onCompleted: toggleOnClose,
      onError: (error) => addAlert({
        id: 'add-edit-whitelist-error',
        severity: 'error',
        message: `Error: ${error.message}`,
      }),
    },
  );
  const handleValueChange = (event) => {
    const { name, value } = event.target;
    const matchSet = {
      emitterRut: setEmitterRut,
      receiverRut: setReceiverRut,
      defaultRate: setDefaultRate,
      monthlyRate: setMonthlyRate,
      retentionRate: setRetentionRate,
    }[name];
    matchSet(value);
  };
  const disabledIfAnyNullRate = useCallback(() => {
    if (defaultRate === '' || monthlyRate === '' || retentionRate === '') return true;
    return false;
  }, [defaultRate, monthlyRate, retentionRate]);
  return (
    <>
      <Button
        onClick={toggleDialog}
        loading={loading}
        variant="contained"
        disabled={false}
        size="small"
      >
        {isEditCell ? 'Editar' : 'Agregar Whitelist'}
      </Button>
      <FingoDialog
        title={`${isEditCell ? 'Editar' : 'Agregar'} Whitelist`}
        open={open}
        handleClose={toggleOnClose}
        maxWidth="sm"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            onClick={editRateWhitelist}
            loading={loading}
            disabled={(emitterRut === '' && receiverRut === '') || disabledIfAnyNullRate()}
          >
            Confirmar
          </LoadingButton>
        )}
      >
        <Stack direction="column" spacing={3} sx={{ marginTop: 1 }}>
          <Stack direction="row" spacing={2} sx={{ alignSelf: 'center' }}>
            <TextField
              variant="outlined"
              value={emitterRut}
              disabled={isEditCell}
              onChange={handleValueChange}
              id="emitterRut"
              label="Rut Emisor"
              name="emitterRut"
              type="string"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              variant="outlined"
              value={receiverRut}
              disabled={isEditCell}
              onChange={handleValueChange}
              id="receiverRut"
              label="Rut Receptor"
              name="receiverRut"
              type="string"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2} sx={{ alignSelf: 'center' }}>
            <TextField
              variant="outlined"
              value={monthlyRate}
              onChange={handleValueChange}
              id="monthlyRate"
              label="Tasa"
              name="monthlyRate"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
              }}
            />
            <TextField
              variant="outlined"
              value={defaultRate}
              onChange={handleValueChange}
              id="defaultRate"
              label="Mora"
              name="defaultRate"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
              }}
            />
            <TextField
              variant="outlined"
              value={retentionRate}
              onChange={handleValueChange}
              id="retentionRate"
              label="Retención"
              name="retentionRate"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (<InputAdornment position="end">%</InputAdornment>),
              }}
            />
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

AddOrEditWhitelistCell.propTypes = {
  whitelist: PropTypes.shape({
    id: PropTypes.string,
    active: PropTypes.bool,
    defaultRate: PropTypes.number,
    monthlyRate: PropTypes.number,
    retentionRate: PropTypes.number,
    status: PropTypes.string,
    emitter: PropTypes.shape({
      displayNationalIdentifier: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
    }),
    receiver: PropTypes.shape({
      displayNationalIdentifier: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
    }),
  }),
};

AddOrEditWhitelistCell.defaultProps = {
  whitelist: null,
};

export default AddOrEditWhitelistCell;
