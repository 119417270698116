import React from 'react';
import { Typography } from '@mui/material';
import { MoneyType } from '../../propTypes';
import { formatMoney } from '../../helpers';

const MoneyCell = ({ amount }) => (
  <Typography variant="body2" align="left" noWrap width="inherit">
    {formatMoney(amount, true)}
  </Typography>
);

MoneyCell.propTypes = {
  amount: MoneyType.isRequired,
};

export default MoneyCell;
