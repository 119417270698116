import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { AmountWithIvaMessage } from '../tooltips';
import MoneyCell from './MoneyCell';
import { MoneyType } from '../../propTypes';
import { formatMoney } from '../../helpers';

const AmountWithCreditNoteAndDebt = ({ row }) => {
  const debt = row.invoiceDebt?.debt;

  return (
    <Stack alignItems="flex-start" justifyContent="center" width="100%">
      <Stack spacing={0.5} direction="row">
        <MoneyCell amount={row.amountWithIva} />
      </Stack>
      <Typography
        variant="caption"
        width="inherit"
        fontSize={10}
      >
        Mora: ${debt ? formatMoney(debt) : 0}
      </Typography>
      <AmountWithIvaMessage
        creditNotes={row.creditNotes}
        debitNotes={row.debitNotes}
      />
    </Stack>
  );
};

AmountWithCreditNoteAndDebt.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amountWithIva: MoneyType.isRequired,
    invoiceDebt: {
      debt: MoneyType,
    },
    creditNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
    debitNotes: PropTypes.arrayOf(PropTypes.shape({
      amountWithIva: MoneyType.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

export default AmountWithCreditNoteAndDebt;
