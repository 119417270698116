import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { preofferCacheStatusToText } from '../../helpers';

const FactoringStatusCell = ({ row }) => (
  <Typography variant="body2">
    {preofferCacheStatusToText(row)}
  </Typography>
);

FactoringStatusCell.propTypes = {
  row: PropTypes.shape({
    preofferSelectable: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default FactoringStatusCell;
