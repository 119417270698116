import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { GridEditDateCell } from '@mui/x-data-grid';
import { offerDateToPaySelected } from '../../apollo/reactive-variables';

const MIN_DATE = moment().add(15, 'days');

const DatePickerCell = ({ params }) => {
  const { row: invoice, id } = params;
  const value = offerDateToPaySelected()[id];
  const onChange = (row, newValue) => {
    offerDateToPaySelected({ ...offerDateToPaySelected(), [row.id]: newValue });
  };
  const maxDate = invoice?.dateExpiration
    ?.clone()
    .add(invoice.offer?.fundingDaysAfterExpiration || 60, 'days');

  return (
    <Box sx={{ m: 'auto' }}>
      <GridEditDateCell
        {...params}
        value={value}
        onChange={onChange}
        minDate={MIN_DATE}
        maxDate={maxDate}
      />
    </Box>
  );
};

DatePickerCell.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.shape({
      id: PropTypes.string.isRequired,
      dateExpiration: PropTypes.string,
      offer: PropTypes.shape({
        fundingDaysAfterExpiration: PropTypes.number,
      }),
    }),
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default DatePickerCell;
