import React from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatMoney, addMoneyWithCurrency, multiplyMoneyWithCurrency } from '../../helpers';
import { MoneyType } from '../../propTypes';

const InvoiceWithDebtAmount = ({ row }) => {
  const { amountWithIva, invoiceDebt, offer, paymentDiscounts } = row;
  const debt = invoiceDebt?.debt;
  const retentionRate = offer?.retentionRate;

  return (
    <Stack
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Typography
        variant="body2"
        width="inherit"
        marginBottom={0.5}
      >
        ${amountWithIva ? formatMoney(amountWithIva) : ''}
      </Typography>
      <Typography
        variant="caption"
        width="inherit"
        fontSize={10}
      >
        Girado: ${paymentDiscounts ? formatMoney(paymentDiscounts.paymentAmountAfterNegativeSurplus) : ''}
      </Typography>
      <Typography
        variant="caption"
        width="inherit"
        fontSize={10}
      >
        Mora: ${debt ? formatMoney(debt) : 0}
      </Typography>
    </Stack>
  );
};

InvoiceWithDebtAmount.propTypes = {
  row: PropTypes.shape({
    amountWithIva: MoneyType.isRequired,
    invoiceDebt: {
      debt: MoneyType,
    },
    offer: {
      retentionRate: PropTypes.number,
    },
    paymentDiscounts: {
      paymentAmountAfterNegativeSurplus: PropTypes.number,
    },
  }).isRequired,
};

export default InvoiceWithDebtAmount;
