import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPLOAD_INVOICE_DOCUMENTS, INVOICE_ATTACHABLE_DOCUMENTS } from '../../graphql';
import DocumentChip from './DocumentsChip';
import useGetInvoice from '../../hooks/useGetInvoice';
import { UploadDocumentsManager } from '../inputs';

const initialDocumentsArray = (invoice, handleOpenUploadManager) => [
  {
    code: 'PDF',
    documentType: 'PDF',
    label: 'Pdf de la Factura',
    url: invoice?.documents?.slice().reverse().find((doc) => doc.type.code === 'PDF')?.file.url || '',
  },
  {
    code: 'PURCHASE_ORDER',
    documentType: 'PURCHASE_ORDER',
    label: 'OC',
    url: invoice?.documents?.slice().reverse().find((doc) => doc.type.code === 'PURCHASE_ORDER')?.file.url || '',
  },
  {
    code: 'PORTAL_SCREENSHOT',
    documentType: 'PORTAL_SCREENSHOT',
    label: 'Print Portal',
    url: invoice?.documents?.slice().reverse().find((doc) => doc.type.code === 'PORTAL_SCREENSHOT')?.file.url || '',
  },
  {
    code: 'OTHER_DOCUMENTS',
    documentType: 'OTHER_DOCUMENTS',
    label: 'Otros',
    handleClick: handleOpenUploadManager,
  },
];

const DocumentsCell = ({ invoiceId, showTitle }) => {
  const inputRef = useRef();
  const [documentToUpload, setDocumentToUpload] = useState({});
  const invoice = useGetInvoice(invoiceId, INVOICE_ATTACHABLE_DOCUMENTS);
  const [uploadManagerOpen, setUploadManagerOpen] = useState(false);

  const handleOpenUploadManager = () => {
    setUploadManagerOpen(true);
  };
  const handleCloseUploadManager = () => {
    setUploadManagerOpen(false);
  };
  const [documentsArray, setDocumentsArray] = useState(
    initialDocumentsArray(invoice, handleOpenUploadManager),
  );

  const [loading, setLoading] = useState(false);
  const [uploadInvoiceDocuments] = useMutation(UPLOAD_INVOICE_DOCUMENTS);

  useEffect(() => {
    setDocumentsArray(initialDocumentsArray(invoice, handleOpenUploadManager));
  }, [invoice]);

  const handleUpload = async (file, documentType) => {
    try {
      setLoading(true);
      const { data } = await uploadInvoiceDocuments({
        variables: {
          invoiceId: invoice.id,
          inputUploadFiles: [
            {
              documentType,
              file,
            },
          ],
        },
      });

      const uploadedDocumentUrl = data.uploadInvoiceDocuments.invoice.documents
        ?.slice().reverse().find((doc) => doc.type.code === documentType)?.file.url;

      if (uploadedDocumentUrl) {
        setDocumentsArray((prevDocuments) => prevDocuments.map((doc) => (
          doc.documentType === documentType ? { ...doc, url: uploadedDocumentUrl } : doc)));
      }
    } catch (error) {
      console.error('Error al cargar el documento:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (!documentToUpload || !documentToUpload.documentType) {
      console.error('No hay ningún documento seleccionado para cargar');
      return;
    }
    handleUpload(file, documentToUpload.documentType);
  };

  const handleOpenInput = (document) => {
    if (document.code === 'OTHER_DOCUMENTS') {
      handleOpenUploadManager();
    } else {
      setDocumentToUpload(document);
      inputRef.current.click();
    }
  };

  return (
    <>
      <Grid container direction="row">
        {showTitle && <h2 style={{ padding: '0' }}>Título</h2>}
        {documentsArray.map((doc) => (
          <Grid item key={`${doc.code.toLowerCase()}-item`}>
            <DocumentChip
              document={doc}
              documentToUpload={documentToUpload}
              inputRef={inputRef}
              handleChange={handleChange}
              loading={loading}
              handleOpenInput={handleOpenInput}
              url={doc.url}
            />
          </Grid>
        ))}
        <input type="file" ref={inputRef} onChange={handleChange} style={{ display: 'none' }} />
      </Grid>
      {uploadManagerOpen && (
        <UploadDocumentsManager
          open={uploadManagerOpen}
          handleClose={handleCloseUploadManager}
          invoiceId={invoiceId}
        />
      )}
    </>
  );
};

DocumentsCell.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

DocumentsCell.defaultProps = {
  showTitle: false,
};

export default DocumentsCell;
