import PropTypes from 'prop-types';
import React from 'react';
import { Stack } from '@mui/material';
import FingoLink from '../links/FingoLink';
import { useBooleanState } from '../../hooks';
import { DocumentsPurchaseOrderDialog } from '../dialogs';

const PurchaseOrderNumberCell = ({ purchaseOrderId, orderNumber }) => {
  const [open, toggleBoolean, setTrue] = useBooleanState(false);

  return (
    <Stack justifyContent="center" display="flex">
      <FingoLink
        textAlign="center"
        variant="body2"
        underline="hover"
        onClick={setTrue}
      >
        {orderNumber}
      </FingoLink>
      {open && (
      <DocumentsPurchaseOrderDialog
        purchaseOrderId={purchaseOrderId}
        open={open}
        setOpen={toggleBoolean}
      />
      )}
    </Stack>
  );
};

PurchaseOrderNumberCell.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
  orderNumber: PropTypes.string.isRequired,
};

export default PurchaseOrderNumberCell;
