import React from 'react';
import PropTypes from 'prop-types';
import { Link as MuiLink } from '@mui/material';
import { MoneyType } from '../../propTypes';
import MoneyCell from './MoneyCell';
import { useBooleanState } from '../../hooks';
import { FingoDialog } from '../dialogs';
import ObjectCreditLineMovements from '../../views/creditLine/ObjectCreditLineMovements';

const CreditLineMovementHistory = ({ row }) => {
  const { globalAppId, amountUsedInEntityLine } = row;
  const [open, toggleOpen] = useBooleanState();
  return (
    <>
      <MuiLink
        textAlign="initial"
        variant="body2"
        rel="noopener noreferrer"
        underline="hover"
        onClick={toggleOpen}
      >
        <MoneyCell amount={amountUsedInEntityLine} />
      </MuiLink>
      <FingoDialog
        open={open}
        handleClose={toggleOpen}
        title="Movimientos de línea de crédito"
        maxWidth="lg"
        fullWidth
      >
        <ObjectCreditLineMovements globalAppId={globalAppId} />
      </FingoDialog>
    </>
  );
};

CreditLineMovementHistory.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
    amountUsedInEntityLine: MoneyType.isRequired,
  }).isRequired,
};

export default CreditLineMovementHistory;
